import React, { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import Text from '../../../../components/Text';
import PrimaryButton from '../../../../components/PrimaryButton';
import { mobile } from 'styles/breakpoints';
import { getLocalisedProduct } from 'utils/localization';
import { Product, Subscription } from 'types/product';
import { DynamicImage } from '../../../../components/images/DynamicImage';
import { useDispatch } from 'react-redux';
import { updateSubscriptionConfig } from 'state/user/effects';
import DiscountSubscriptionPlan from './DiscountSubscriptionPlan';
import _ from 'lodash';

interface DiscountSubscriptionProps {
  products: {
    oldProduct: Product;
    newProduct: Product;
  };
  onGetMyAccess: (product: Product) => void;
  terms?: {
    text1: string;
    text2: string;
    text3: string;
    text4: string;
    termsLink: string;
    privacyLink: string;
  };
  title?: string;
  riskFreeImg?: string;
  riskFreeImgAlt: string;
  paymentsImg?: string;
  paymentsImgAlt: string;
  subscriptionConfig?: {
    title: string;
    monthFree?: string;
    selectBtnText: string;
    pricePerDayText: string;
    billingPeriod: {
      text1: string;
      text2: string;
    };
    tag: {
      bestValueText: string;
      mostPopularText: string;
    };
    showPricePerWeek?: boolean;
  };
  selectBtnText: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #f9f6f4;
  padding: 4rem 1rem 2rem 1rem;
  @media ${mobile} {
    padding: 2rem 1rem 0 1rem;
  }
`;

const Terms = styled(Text).attrs({
  type: 'bodyM',
  color: 'dark80',
})`
  margin: 1.5rem 0 0;
  text-align: center;
  -webkit-text-size-adjust: 100%;
  max-width: 21.4375rem;

  @media ${mobile} {
    margin: 2rem 0 0;
  }
`;

const BoldHighlight = styled.span`
  font-family: ${({ theme }) => theme.typography.bodyM.fontFamily};
  font-size: ${({ theme }) => theme.typography.bodyM.fontSize}rem;
  line-height: ${({ theme }) => theme.typography.bodyM.lineHeight}rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.green};
  cursor: pointer;
`;

const Subscriptions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

const PrimaryButtonStyled = styled(PrimaryButton)`
  width: 100%;
  margin-top: 1.5rem;
  max-width: 33rem;
`;

const DiscountSubscription: FC<DiscountSubscriptionProps> = ({
  products,
  onGetMyAccess,
  paymentsImg,
  paymentsImgAlt,
  terms,
  subscriptionConfig,
  selectBtnText,
}) => {
  const [subscriptions, setSubscriptions] = useState<{
    oldSubscription: Subscription;
    newSubscription: Subscription;
  } | null>(null);

  const dispatch = useDispatch();

  const handleGetMyAccess = useCallback((product: Product) => {
    dispatch(updateSubscriptionConfig(subscriptionConfig || ('' as any)));
    onGetMyAccess(product);
  }, []);

  const handleTerms = useCallback(() => {
    window.open(terms?.termsLink);
  }, []);

  const handlePrivacy = useCallback(() => {
    window.open(terms?.privacyLink);
  }, []);

  const handleSubscriptions = useCallback(() => {
    if (!products) return;
    const oldSubscription = getLocalisedProduct(products.oldProduct);
    const newSubscription = getLocalisedProduct(products.newProduct);
    if (oldSubscription && newSubscription) {
      setSubscriptions({ oldSubscription, newSubscription });
    }
  }, [products]);

  useEffect(() => {
    handleSubscriptions();
  }, [products]);

  if (!subscriptions) return null;

  const oldFinalPrice = Number(subscriptions.oldSubscription.finalPrice);
  const newFinalPrice = Number(subscriptions.newSubscription.finalPrice);

  const subscriptionPercentage = _.round(
    (1 - newFinalPrice / oldFinalPrice) * 100
  );

  const renderSubscription = (subscription?: Subscription, isNew?: boolean) => {
    if (!subscriptionConfig || !subscription) return null;
    const monthPlanText = `${subscription?.months}${subscriptionConfig?.title}`;
    const finalText =
      subscriptionConfig?.billingPeriod.text1 +
      subscription?.months +
      subscriptionConfig?.billingPeriod.text2;
    const currency = subscription?.currency;
    return (
      <DiscountSubscriptionPlan
        priceText={isNew ? 'New Price' : 'Old Price'}
        monthPlanText={monthPlanText}
        perDayPrice={`${currency}${subscription?.discountedDailyPrice}`}
        perDayText={subscriptionConfig?.pricePerDayText}
        finalPrice={`${currency}${subscription?.finalPrice}`}
        finalText={finalText}
        isNew={isNew}
        subscriptionPercentage={subscriptionPercentage}
      />
    );
  };

  return (
    <Container>
      <Subscriptions>
        {renderSubscription(subscriptions?.oldSubscription)}
        {renderSubscription(subscriptions?.newSubscription, true)}
      </Subscriptions>
      <PrimaryButtonStyled
        cornerVariant="round"
        onClick={() => handleGetMyAccess(products.newProduct)}
      >
        {selectBtnText}
      </PrimaryButtonStyled>
      {terms && (
        <Terms>
          {terms.text1}
          {<BoldHighlight onClick={handleTerms}>{terms.text2}</BoldHighlight>}
          {terms.text3}
          {<BoldHighlight onClick={handlePrivacy}>{terms.text4}</BoldHighlight>}
        </Terms>
      )}
      {paymentsImg ? (
        <DynamicImage src={paymentsImg} alt={paymentsImgAlt} marginTop="2rem" />
      ) : null}
    </Container>
  );
};

export default React.memo(DiscountSubscription);
